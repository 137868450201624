<template>

	<div v-if="product">
		<div >
			<div style="margin-bottom: 2rem" class="w-calc-test" >
				<div class="w-calc-test-table">
					<table border="1" class="table">
						<tr>
							<td></td>
							<td v-for="(td, index) in widths" :class="{'--hight-light':widthLight(td)}"><b>{{td}}</b></td>
						</tr>
						<tr v-for="(row,height) in matrix">
							<td :class="{'--hight-light':heightLight(height)}"><b>{{height}}</b></td>
							<td v-for="(td, index) in row"
								:class="{
                    		'--hight-light':widthLight(td.width) || heightLight(height),
                    		'--select-light':widthLight(td.width) && heightLight(height)
                    	}"
							>{{td ? td.price : ''}}</td>
						</tr>
					</table>
				</div>
				<div class="w-calc-test-inputs" v-if="false">
					<div class="w-calc-test-inputs-section">
						<div class="w-calc-test-inputs-section-grid">
							<span><b>Width {{width}}</b></span>
							<input type="number"  :min="minWidth" :max="maxWidth" ref="widthInput" @change="setWidth($event.target.value)" v-model="width">

						</div>
						<div>Limit {{widthSteps}}</div>
						<div>next step <span>{{nextWidthStep}}</span></div>
						<div>
							<div>min width {{minWidth}}</div>
							<div>max width {{maxWidth}}</div>
						</div>
					</div>
					<div class="w-calc-test-inputs-section">
						<div class="w-calc-test-inputs-section-grid">
							<span><b>Height {{height}}</b></span>
							<input type="number" v-model="height" ref="heightInput" @change="setHeight($event.target.value)">

						</div>
						<div>Limit {{heightSteps}}</div>
						<div>
							<div>min height {{minHeight}}</div>
							<div>max height {{maxHeight}}</div>
						</div>
					</div>
				</div>
			</div>
			<br><br>
		</div>


		<div class="calc-main">
			<div class="calc-main-preloader" v-if="state.isLoading">
				<div class="preloader"></div>
			</div>
			<div class="calc-main-left">
				<div class="calc-main-visual">
					<div class="calc-main-visual-title">{{product.name}}</div>
					<div class="calc-main-visual-picture" @click.prevent="back()" style="cursor: pointer" title="Een ander model kiezen">
						<img :src="'https://manager.anensa-kozijnen.nl/' + product.image.src" :alt="product.name">
					</div>
				</div>
				<div class="calc-main-visual-double" >
					<div class="calc-main-visual-double-section">
						<div class="calc-main-visual" v-if="profile">
							<div class="calc-main-visual-title">{{profile.name}}</div>
							<div class="calc-main-visual-picture">
								<img :src="'https://manager.anensa-kozijnen.nl/' + profile.image.src" :alt="profile.name">
							</div>
						</div>
					</div>
					<div class="calc-main-visual-double-section">
						<div class="calc-main-visual" v-if="glass">
							<div class="calc-main-visual-title">{{glass.name}}</div>
							<div class="calc-main-visual-picture">
								<img :src="'https://manager.anensa-kozijnen.nl/' + glass.image.src" :alt="glass.name">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="calc-main-aside" >
				<div class="calc-main-aside-header" v-if="product">
					<div>
						<div class="calc-main-aside-title">{{product.name}}</div>
						<a href="javascript:void(0)" @click.prevent="back()">Een ander model kiezen</a>
					</div>
					<div class="calc-main-aside-price" v-if="totalPrice">€{{totalPrice}}</div>
				</div>
				<div class="calc-main-aside-row" v-if="matrix">
					<div class="calc-main-aside-row-title">Breedte in mm</div>
					<div class="calc-main-aside-row-input">
						<input type="number" ref="inputWidth" @change="setWidth($event.target.value)" :min="minWidth" :max="maxWidth"  :value="input.width">
					</div>
				</div>
				<div class="calc-main-aside-row" v-if="matrix">
					<div class="calc-main-aside-row-title">Hoogte in mm</div>
					<div class="calc-main-aside-row-input">
						<input type="number" ref="inputHeight" @change="setHeight($event.target.value)" :value="input.height">
					</div>
				</div>
				<div class="calc-main-aside-row" v-if="minHeightByWidth && maxHeightByWidth && input.width">
					<div class="calc-main-aside-row-title"></div>
					<div class="calc-main-aside-row-content">
						<div class="bloquote green">
							<div>Base price: <b>{{basePrice}}</b></div>
							<div>Bij aangegeven breedte: <b>{{input.width}}</b> mm</div>
							<div>De minimale hoogte is: <b>{{minHeightByWidth}}</b> mm</div>
							<div>De maximale is: <b>{{maxHeightByWidth}}</b> mm</div>
						</div>
					</div>
				</div>
				<div class="calc-main-aside-row">
					<div class="calc-main-aside-row-title">Profiel</div>
					<div class="calc-main-aside-row-input">
						<select v-model="state.profile">
							<option :value="index" v-for="(profile, index) in profiles">{{profile.name}}</option>
						</select>
					</div>
				</div>
				<div class="calc-main-aside-row" >
					<div class="calc-main-aside-row-title">
						Aanslag
					<div style="color: #5b5b5b; font-style: italic;"><small>€{{aanslagPrice}}</small></div>
					</div>
					<div class="calc-main-aside-row-input">
						<select v-model="state.aanslag">
							<option :value="true">Ja</option>
							<option :value="false" disabled hidden>Nee</option>
						</select>
					</div>
				</div>
				<div class="calc-main-aside-row" v-if="minHeight && maxHeight && input.width">
					<div class="calc-main-aside-row-title"></div>
					<div class="calc-main-aside-row-content">
						<div class="bloquote">
							<div>Binnenwerkse breedte: <b>{{aanslag.width}}</b> mm</div>
							<div>Binnenwerkse hoogte: <b>{{aanslag.height}}</b> mm</div>
						</div>
					</div>
				</div>
				<div class="calc-main-aside-row">
					<div class="calc-main-aside-row-title">
						Kleur
						<div style="color: #5b5b5b; font-style: italic;"><small>€{{colorPrice}}</small></div>
					</div>
					<div class="calc-main-aside-row-input">
						<select v-model="state.color">
							<option :value="index" v-for="(color, index) in colors">{{color.name}}</option>
						</select>
					</div>
				</div>
				<div class="calc-main-aside-row">
					<div class="calc-main-aside-row-title">
						Glas
						<div style="color: #5b5b5b; font-style: italic;"><small>€{{glassPrice}}</small></div>
					</div>
					<div class="calc-main-aside-row-input">
						<select v-model="state.glass">
							<option :value="index" v-for="(glass, index) in glasses">{{glass.name}}</option>
						</select>
					</div>
				</div>
				<br>
				<div class="calc-main-aside-price" v-if="totalPrice"><span style="font-weight: 400">Prijs</span> €{{totalPrice}}</div>
			</div>
		</div>
	</div>

</template>
<script setup>

	import useApi from "@/composables/useApi";
	import {onMounted, ref, computed, watch} from "vue";

	let {loadColors, loadGlasses, loadPrice, loadProfiles, loadProductById} = useApi();

	let inputWidth = ref(null)
	let inputHeight = ref(null)

	let colors = ref([]);
	let glasses = ref([]);
	let profiles = ref([]);
	let product = ref(null);
	let prices = ref(null);

	let state = ref({
		isLoading:true,
		aanslag:true,
		profile:0,
		color:0,
		glass:0,
	});
	let input = ref({
		width:null,
		height:null,
	});

	let profile = computed(() => profiles.value[state.value.profile]);
	let glass = computed(() => glasses.value[state.value.glass]);
	let color = computed(() => colors.value[state.value.color]);

	let aanslag = computed(() => {
		return {
			'width':state.value.aanslag ? input.value.width - 44 : input.value.width,
			'height':state.value.aanslag ? input.value.height - 44 : input.value.height,
		}
	});

	const pricesValues = computed(() => Array.isArray(prices.value?.values) ? prices.value.values : null);
	const widths = computed(() => {
		if(!Array.isArray(pricesValues.value)) return false;
		let list = pricesValues.value.map(p => p.width)
		list = [...new Set(list)];
		list = list.sort((a,b) => a - b);
		return list;
	})
	const heights = computed(() => {
		if(!Array.isArray(pricesValues.value)) return false;
		let list = pricesValues.value.map(p => p.height)
		list = [...new Set(list)];
		list = list.sort((a,b) => a - b);
		return list;
	})
	const matrix = computed(() => {
		if(!Array.isArray(heights.value) || !Array.isArray(widths.value)) return false;
		let matrix = {};
		let widthsLocal = JSON.parse(JSON.stringify(widths.value));
		let heightsLocal = JSON.parse(JSON.stringify(heights.value));
		let pricesValuesLocal = JSON.parse(JSON.stringify(pricesValues.value));

		heightsLocal.forEach(h => {
			if(!matrix[h]) matrix[h] = [];
			widthsLocal.forEach(w => {
				let price = pricesValuesLocal.find(p => p.width == w && p.height == h);
				matrix[h].push({
					width:w,
					height:h,
					price:price?.price ? price?.price : null
				})
			})
		})
		return matrix;
	})
	const minWidth = computed(() => {
		if(!Array.isArray(pricesValues.value)) return false;
		let min = pricesValues.value.reduce((min, obj) => obj.width < min.width ? obj : min);
		return min.width;
	})
	const maxWidth = computed(() => {
		if(!Array.isArray(pricesValues.value)) return false;
		let max = pricesValues.value.reduce((min, obj) => obj.width > min.width ? obj : min);
		return max.width;
	})
	const minHeight = computed(() => {
		if(!Array.isArray(heights.value)) return false;
		return heights.value.reduce((min, item) => item < min ? item : min);
	})
	const maxHeight = computed(() => {
		if(!Array.isArray(heights.value)) return false;
		return heights.value.reduce((min, item) => item > min ? item : min);
	})
	// Максимальная и минимальная высота по введенной ширине
	const minHeightByWidth = computed(() => {
		if(!Array.isArray(widths.value) ||  !Array.isArray(pricesValues.value) || !input.value.width) return false;
		let finding = findBoundingNumbers(widths.value, input.value.width);
		let localHeights = pricesValues.value.filter(p => finding[0] == p.width)
		if(!localHeights.length) return false;
		localHeights = localHeights.map(p => p.height);
		return localHeights.reduce((min, item) => item < min ? item : min);
	})
	const maxHeightByWidth = computed(() => {
		if(!Array.isArray(widths.value) ||  !Array.isArray(pricesValues.value) || !input.value.width) return false;
		let finding = findBoundingNumbers(widths.value, input.value.width);
		let localHeights = pricesValues.value.filter(p => finding[0] == p.width)
		if(!localHeights.length) return false;
		localHeights = localHeights.map(p => p.height);
		return localHeights.reduce((min, item) => item > min ? item : min);
	})
	const nextWidthStep  = computed(() => {
		if(!input.value.width) return false;
		let steps = JSON.parse(JSON.stringify(widths.value));
		steps = steps.sort((a, b) => a - b);
		for (let i = 0; i < steps.length; i++) {
			if (steps[i] > input.value.width) { return steps[i]}
		}
		return steps[ steps.length - 1];
	});

	/**
	 * Prices
	 * */

	const basePrice = computed(() => {
		if(!pricesValues.value) return false;
		let pricesValuesLocal = JSON.parse(JSON.stringify(pricesValues.value));
		let inputWidth = input.value.width;
		let inputHeight = input.value.height;
		let result = pricesValuesLocal.filter(p => widthLight(p.width) && heightLight(p.height))
		let basePrice = interpolatePrice(inputWidth, inputHeight, result);
		return  normalizePrice(basePrice ? parseFloat(basePrice) : 0);
	})


	const aanslagPrice = computed(() => {
		if(!state.value.aanslag) return 0;
		const price = basePrice.value * 0.1;
		return normalizePrice(price ? parseFloat(price) : 0);
	})
	const colorPrice = computed(() =>{
		if(!color.value || !color.value.percent) return 0;
		const price = basePrice.value * (parseInt(color.value.percent) / 100);
		return normalizePrice(price ? parseFloat(price) : 0);
	})
	const glassPrice = computed(() => {
		if(!glass.value || !glass.value.percent) return 0;
		const price = basePrice.value * (parseInt(glass.value.percent) / 100);
		return normalizePrice(price ? parseFloat(price) : 0);
	})

	const  back = () => {
		window.location = window.location.pathname;
	}

	const totalPrice = computed(() => {

		let prices = [
			parseFloat(basePrice.value),
			parseFloat(aanslagPrice.value),
			parseFloat(colorPrice.value),
			parseFloat(glassPrice.value),
		]

		var price = prices.reduce((accumulator, currentValue) => {
			return accumulator + currentValue
		},0);

		return !Number.isNaN(price) ? normalizePrice(price) : 0
	})

	/**
	 *  Methods
	 * */

	const interpolatePrice = (needleWidth, needleHeight, prices) => {

		let xMin = Math.min(...prices.map(item => item.width));
		let xMax = Math.max(...prices.map(item => item.width));

		let x1x3 = prices.filter(p => p.width == xMin);
		let x2x4 = prices.filter(p => p.width == xMax);

		x1x3 = x1x3.sort((a,b) => a.width - b.width);
		x2x4 = x2x4.sort((a,b) => a.width - b.width);

		let x1 = x1x3.reduce((min, item) => (item.width < min.width && item.height < min.height) ? item : min, x1x3[0]);
		let x3 = x1x3.find(x => x != x1);
		let x2 = x2x4.reduce((min, item) => (item.width < min.width && item.height < min.height) ? item : min, x2x4[0]);
		let x4 = x2x4.find(x => x != x2);

		x2 = x2 == x1 ? null : x2;
		x4 = x4 == x3 ? null : x4;

		const interpolate = (v, v1, v2, p1, p2) => {
			return p1 + (p2 - p1) * (v - v1) / (v2 - v1);
		};

		if (x2 && x4 && needleWidth < x2.width && needleHeight < x4.height) {
			const priceWidth1 = interpolate(needleWidth, x1.width, x2.width, x1.price, x2.price);
			const priceWidth2 = interpolate(needleWidth, x3.width, x4.width, x3.price, x4.price);

			const finalPrice = interpolate(needleHeight, x1.height, x3.height, priceWidth1, priceWidth2);
			return finalPrice;

		} else if (x2 && needleWidth < x2.width) {
			const finalPrice = interpolate(needleWidth, x1.width, x2.width, x1.price, x2.price);
			return finalPrice;

		} else if (x3 && needleHeight < x3.height) {
			const finalPrice = interpolate(needleHeight, x1.height, x3.height, x1.price, x3.price);
			return finalPrice;

		} else {
			return x1.price; // В случае выхода за границы, можно вернуть ближайшую известную цену
		}
	}
	const normalizePrice = (price = 0) => (new Number(price)).toFixed(2)

	const findMinEqual = (w,items) => {
		let itms = JSON.parse(JSON.stringify(items));
		itms = itms.sort((a,b) => parseInt(b.width) - parseInt(a.width));
		return itms.find(i => parseInt(i.width) <= parseInt(w))
	}
	const findMaxEqual = (w,items) => {
		let itms = JSON.parse(JSON.stringify(items));
		itms = itms.sort((a,b) => parseInt(a.width) - parseInt(b.width));
		return itms.find(i => parseInt(i.width) >= parseInt(w))
	}
	const createMiddlePrice = (w, items) => {
		let minValue = findMinEqual(w,items)
		let maxValue = findMaxEqual(w,items)
		if(!minValue || !maxValue) return false;
		let newValue = JSON.parse(JSON.stringify(minValue));
		let minPrice = parseFloat(minValue.price);
		let maxPrice = parseFloat(maxValue.price);
		let coefficient = (maxPrice - minPrice) / (parseInt(maxValue.width) - parseInt(minValue.width));
		newValue.price = minPrice + coefficient.toFixed(2) * (parseInt(w) - parseInt(minValue.width));
		newValue.price = newValue.price.toFixed(1);
		newValue.width = w;
		return newValue;
	}
	const processPrices = computed(() =>{
		if(!matrix.value) return false;
		let matrix = JSON.parse(JSON.stringify(matrix.value))
		let width = JSON.parse(JSON.stringify(widths.value));
		let result = {};

		for (const key in matrix) {
			let items = matrix[key];
			let needleWidths = width.filter(w => !items.find(i => i.width == w));
			needleWidths.forEach(w => {
				let p = this.createMiddlePrice(w, items);
				items.push(p)
			});
			items = items.sort((a,b) => parseInt(a.width) - parseInt(b.width));
			result[key] = items;
		}
		return result;
	})
	const findBoundingNumbers = (arr, target) => {
		// Сортируем массив
		arr = arr.sort((a, b) => a - b);

		// Если целевое значение меньше или равно минимальному значению массива
		if (target <= arr[0]) {
			//return [arr[0]];
		}

		// Если целевое значение больше или равно максимальному значению массива
		if (target >= arr[arr.length - 1]) {
			return [arr[arr.length - 1]];
		}

		// Ищем два числа, между которыми находится target
		for (let i = 0; i < arr.length - 1; i++) {
			if (arr[i] <= target && target < arr[i + 1]) {
				return [arr[i], arr[i + 1]];
			}
		}

		// Если target совпадает с одним из значений массива
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === target) {
				return [arr[i], arr[i + 1]];
			}
		}

		// Если target не попал ни под одно из условий, возвращаем пустой массив
		return [];
	}
	const widthLight = (td) => {
		if(!Array.isArray(heights.value) || !input.value.width) return false;
		let finding = findBoundingNumbers(widths.value, input.value.width);
		return finding.includes(parseFloat(td));
	}
	const heightLight = (td) => {
		if(!Array.isArray(heights.value) || !input.value.height) return false;
		let finding = findBoundingNumbers(heights.value, input.value.height);
		return  finding.includes(parseFloat(td));
	}
	const addError = (name, input, value) => console.error;
	const setHeight = (height) => {
		let tmpHeight = height ? height : parseFloat(input.value.height);
		let value = new String(tmpHeight ? tmpHeight : 0);
		console.log('current height', value);
		if(!value){
			value = minHeightByWidth.value;
			input.value.height = parseFloat(value);
		}else{
			value = parseFloat(value.replace(',', '.'));
			if(value < minHeightByWidth.value){
				value = minHeightByWidth.value;
				addError('heightInput', 'heightMinValue', {height:value});
			}
			if(value > maxHeightByWidth.value) {
				value = maxHeightByWidth.value;
				addError('heightInput', 'heightMaxValue', {height:value});
			}

			input.value.height = parseFloat(value);

		}

	}
	const setWidth = (value = '') => {
		value = new String( value ? value : 0);
		value = parseFloat(value.replace(',', '.'));
		if(value < minWidth.value){
			value = minWidth.value;
			addError('widthInput', 'widthMinValue', {width:value});
		}
		if(value > maxWidth.value) {
			value = maxWidth.value;
			addError('widthInput', 'widthMaxValue', {width:value});
		}

		input.value.width = parseFloat(value);

		setHeight();
	}
	const onMountedHandler = async () => {

		let p = (new URL(window.location.href)).searchParams;
		let type_id = p.get('type_id');
		let product_id = p.get('product_id');

		state.value.isLoading = true;
		product.value = await loadProductById(product_id);
		colors.value = await loadColors({'type_id':type_id});
		glasses.value = await loadGlasses({'type_id':type_id});
		profiles.value = await loadProfiles({'type_id':type_id});
		state.value.isLoading = false;

	}

	watch(() => profile?.value, async () => {
		state.value.isLoading = true;
		prices.value = await loadPrice(product?.value?.id, profile?.value?.id);
		state.value.isLoading = false;
		setWidth();
	})

	onMounted(onMountedHandler);



/*
export default {
	props:['params', 'data'],
	data(){
		return {
			width:0,
			height:0,
			profileId:null,
			glassId:null,
			jsonResult:null,
			state:{
				glass:0,
				color:0,
				profile:0,
				aanslag:true,
			}
		}
	},
	computed:{
		result(){
			if(!this.jsonResult || !this.profile) return false;
			const itemProfile = this.jsonResult.find(i => i.profile == this.profile.id)
			return itemProfile?.prices.map(item => {
				item.price = parseFloat(item.price).toFixed(1);
				item.width = parseFloat(item.width);
				item.height = parseFloat(item.height);
				return item;
			});
		},
		prices(){ // сгруппироваынный список по ширине
			if(!Array.isArray(this.result) || !this.result.length) return false;
			let result = {};
			let localPrices = JSON.parse(JSON.stringify(this.result));
			localPrices = localPrices.sort((a,b) => parseInt(a.width) - parseInt(b.width));
			localPrices.forEach(price => {
				let height = price.height;
				if(!result[height]) result[height] = [];
				result[height].push(price);
			});
			return result;
		},
		processPrices(){
			if(!this.prices) return false;
			let result = {};


			for (const key in this.prices) {
				let items = this.prices[key];
				let needleWidths = this.widthSteps.filter(w => !items.find(i => i.width == w));

				needleWidths.forEach(w => {
					let p = this.createMiddlePrice(w, items);
					items.push(p)
				});

				items = items.sort((a,b) => parseInt(a.width) - parseInt(b.width));
				result[key] = items;
			}
			return result;
		},
		maxWidthCellsCount(){
			let count = 0;
			Object.values(this.prices).forEach(items => count = items.length > count ? items.length : count);
			return new Array(count);
		},
		widthSteps(){
			let items = [];
			if(!this.result || !this.result.length) return [];
			this.result.forEach(item => items.push(item.width));
			items = [...new Set(items)];
			items = items.sort((a,b) => a - b);
			return items;
		},
		nextWidthStep(){
			if(!this.width) return false;
			let steps = JSON.parse(JSON.stringify(this.widthSteps));
			steps = steps.sort((a, b) => a - b);
			for (let i = 0; i < steps.length; i++) {
				if (steps[i] > this.width) {
					return steps[i];
				}
			}
			return steps[ steps.length - 1];
		},
		minWidth(){
			if(!this.result) return false;
			let min = this.result.reduce((min, obj) => obj.width < min.width ? obj : min);
			return min.width;
		},
		maxWidth(){
			if(!this.result) return false;
			let max = this.result.reduce((max, obj) => obj.width > max.width ? obj : max);
			return max.width;
		},
		heightSteps(){
			let items = [];
			let object = this.processPrices;

			const needleWidth = object[this.width] ? this.width : this.nextWidthStep;

			Object.keys(object).forEach((key) => {
				let widthItems = object[key];

				let isFinded = widthItems.find(i => i.width == needleWidth)
				if(isFinded){
					items.push(parseFloat(key));
				}
			})
			return items;
		},
		minHeight(){
			if(!this.result) return false;
			let min = this.heightSteps.reduce((min, item) => item < min ? item : min);
			return min;
		},
		maxHeight(){
			if(!this.result) return false;
			let max = this.heightSteps.reduce((max, item) => item > max ? item : max);
			return max;
		},
		limitHeight(){
			if(!this.minWidth || !this.result) return false;
			let minW = this.minWidth;
			let result = this.prices[minW.height]
			console.log(minW)
			console.log(result)
			return result;
		},




		product(){
			if(!this.params.product || !this.params.variant ||  !Array.isArray(this.data.products)) return false;
			return this.data.products.find(p => p.product == this.params.product && p.variant == this.params.variant);
		},
		profiles(){
			if(!Array.isArray(this.data.profiles)) return false;
			return this.data.profiles;
		},
		profile(){
			if(!this.profiles) return false;
			return this.profiles[this.state.profile];
		},
		glasses(){
			if(!Array.isArray(this.data.glasses)) return false;
			return this.data.glasses;
		},
		glass(){
			if(!this.glasses) return false;
			return this.glasses[this.state.glass];
		},
		colors(){
			if(!Array.isArray(this.data.colors)) return false;
			return this.data.colors;
		},
		color(){
			if(!this.colors) return false;
			return this.colors[this.state.color];
		},
		totalPrice(){
			return 0;
		}
	},
	methods:{
		createMiddlePrice(w, items){
			let minValue = this.findMinEqual(w,items)
			let maxValue = this.findMaxEqual(w,items)
			if(!minValue || !maxValue) return false;
			let newValue = JSON.parse(JSON.stringify(minValue));
			let minPrice = parseFloat(minValue.price);
			let maxPrice = parseFloat(maxValue.price);
			let coefficient = (maxPrice - minPrice) / (parseInt(maxValue.width) - parseInt(minValue.width));
			newValue.price = minPrice + coefficient.toFixed(2) * (parseInt(w) - parseInt(minValue.width));
			newValue.price = newValue.price.toFixed(1);
			newValue.width = w;
			return newValue;
		},
		findMinEqual(w,items){
			let itms = JSON.parse(JSON.stringify(items));
			itms = itms.sort((a,b) => parseInt(b.width) - parseInt(a.width));
			return itms.find(i => parseInt(i.width) <= parseInt(w))
		},
		findMaxEqual(w,items){
			let itms = JSON.parse(JSON.stringify(items));
			itms = itms.sort((a,b) => parseInt(a.width) - parseInt(b.width));
			return itms.find(i => parseInt(i.width) >= parseInt(w))
		},
		addError(location = '', code = '', data = {}){
			//console.error(location, code, data);
		},
		setWidth(value = ''){
			value = new String(value);
			value = parseFloat(value.replace(',', '.'));
			if(value < this.minWidth){
				value = this.minWidth;
				this.addError('widthInput', 'widthMinValue', {width:value});
			}
			if(value > this.maxWidth) {
				value = this.maxWidth;
				this.addError('widthInput', 'widthMaxValue', {width:value});
			}
			//this.$refs['widthInput'].value = value
			this.width = parseFloat(value);
			this.setHeight();
		},
		setHeight(){
			let value = new String(this.height);
			if(!value){
				value = this.minHeight;
				this.height = parseFloat(value);
			}else{
				// console.log(value);
				value = parseFloat(value.replace(',', '.'));
				if(value < this.minHeight){
					value = this.minHeight;
					this.addError('heightInput', 'heightMinValue', {height:value});
				}
				if(value > this.maxHeight) {
					value = this.maxHeight;
					this.addError('heightInput', 'heightMaxValue', {height:value});
				}
				this.height = parseFloat(value);
			}
		},
		findBoundingNumbers(arr, target) {
			arr = arr.sort((a, b) => a - b);
			if (target <= arr[0]) {
				//return [arr[0]];
			}
			if (target >= arr[arr.length - 1]) {
				return [arr[arr.length - 1]];
			}
			for (let i = 0; i < arr.length - 1; i++) {
				if (arr[i] <= target && target < arr[i + 1]) {
					return [arr[i], arr[i + 1]];
				}
			}
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] === target) {
					return [arr[i], arr[i + 1]];
				}
			}
			return [];
		},
		widthLight(td){
			if(!Array.isArray(this.widthSteps) || !this.width) return false;
			let finding = this.findBoundingNumbers(this.widthSteps, this.width);
			let isIncludes = finding.includes(parseFloat(td));
			//if(isIncludes) console.log('widthLight', finding, this.width, isIncludes);
			return isIncludes
		},
		heightLight(td){
			if(!Array.isArray(this.heightSteps) || !this.height) return false;
			let finding = this.findBoundingNumbers(this.heightSteps, this.height);
			let isIncludes = finding.includes(parseFloat(td));
			//if(isIncludes) console.log('heightLight', finding, this.height, isIncludes);
			return isIncludes;
		},
		async loadPrices(){
			this.jsonResult = await fetch(`/prices/json2.php?${(new URLSearchParams(this.params)).toString()}`).then(r => r.json());
			this.state.profile = 0;
			if(!this.width) this.width = this.minWidth;
			this.setHeight();
		},
		back(){
			window.location = window.location.pathname;
		},
	},
	watch:{
		'state.profile'(){
			//this.state.profile = 0;
			//if(!this.width) this.width = this.minWidth;
			//this.setHeight();
		},
	},
	async mounted(){
		this.loadPrices();
	}
}
*/
</script>
<style lang="scss">
.w-calc-test{
	display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
	&-table{

	}
	&-inputs{
		display: grid;
		grid-template-columns: 1fr;
		gap:1rem;
		font-size: 14px;;

		&-section{
			&-grid{
				display: grid;
				grid-template-columns: 1fr;
			}
			input{
				padding: 5px;
			}
		}
	}
}

.w-calc-test-inputs-section{
	display: flex;
	flex-direction: column;
}

.--hight-light{
	background: #b7ebb7;
	transition: 300ms;
}

.--select-light{
	background: lightgoldenrodyellow!important;
	transition: 300ms;
}

.table{
	border-collapse: collapse;
}

.table td{
	padding: 2px 5px;
	text-align: center;
}




</style>